import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import auth from "./modules/auth";
import appAlert from "./plugins/appAlert";
import confirmation from "./plugins/confirmtion";
import loader from "./plugins/loader";
import router from "./router";
import { filters } from "./utils";
import dashboard from "./modules/dashboard";
import profile from "./modules/profile";
import preauth from "./modules/preauth";
import status from "./modules/status";
import facilities from "./modules/facilities";
import chat from "./modules/chat";
import pay from "./modules/pay";
import Toast from 'vue-toastification';
import { setupTimers } from "./utils/auto-logout";
import 'vue-toastification/dist/index.css'
// import VueSilentbox from 'vue-silentbox';



Vue.config.productionTip = false;
// Vue.config.devtools = true;

const options = {
  store,
  router,
  mode: 'no-cors'
};



Vue.use(appAlert);
Vue.use(confirmation);
Vue.use(loader);
Vue.use(auth, options);
Vue.use(dashboard, options);
Vue.use(profile, options);
Vue.use(status, options);
Vue.use(facilities, options);
Vue.use(preauth, options);
Vue.use(chat, options);
Vue.use(pay, options);
Vue.use(Toast);
Vue.use(setupTimers);
// Vue.use(VueSilentbox);


/* Register Filters */
filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
