export default {
  login: "api_login",
  verifyOtp: "api_validatepassword",
  register: "api_register",
  submitQuery:"api_add_query",
  sendQuery:"api_add_query",
  validate: "api_idlookup",
  forgot:"api_forgotpassword",
  LINDAMAMAHOS:"api_maternity_hospitalregistration",
  SELFLINDAMAMAHOS:"api_maternity_selfregistration",
  GUARDLINDAMAMAHOS:"api_maternity_guardianregistration"
};
