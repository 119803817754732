<template>
  <v-app>
    <!--  Side Bar  -->
    <v-navigation-drawer
      dark
      class="px-1 drawerColor"
      app
      clipped
      v-model="drawer"
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }"
    >
      <v-divider></v-divider>

      <v-list nav class="pa-0 mt-3">
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            class="nav-link"
            v-for="item in navLinks"
            :key="item.title"
            :to="item.to"
            :href="item.href"
            :disabled="item.disabled"
          >
            <v-list-item-icon class="ml-2">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="ml-n3">
              <v-list-item-title class="nav-title"
                >{{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#ffffff" clipped-left class="overflow-hidden">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.smAndDown"
      ></v-app-bar-nav-icon>
      <v-app-bar-title>
        <img
          @click="redirect()"
          src="../../../assets/nhif-logo.png"
          :height="$vuetify.breakpoint.mdAndUp ? 100 : '80'"
          :class="$vuetify.breakpoint.mdAndUp ? 'justify-right' : 'ml-8'"
          alt="NHIF"
        />
      </v-app-bar-title>

      <v-spacer />

      <v-btn
        dark
        v-if="!auth.check()"
        text
        :to="{ name: 'Login' }"
        class="mx-2"
      >
        signIn
      </v-btn>

      <v-btn
        text
        v-if="auth.check() && $vuetify.breakpoint.mdAndUp"
        @click="logout()"
      >
        SignOut
        <v-icon right>mdi-logout</v-icon>
      </v-btn>
      <div
        class="text-center"
        v-if="auth.check() && !$vuetify.breakpoint.mdAndUp"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              mdi-account-circle
            </v-icon>
          </template>
          <v-list dense class="overflow-hidden" height="50">
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content> signout </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <loader />
    <app-footer />
  </v-app>
</template>

<script>
import { AuthService } from "@/modules/auth";
import AppFooter from "@/components/app-footer";

export default {
  name: "home",
  components: { AppFooter },
  data: () => ({
    drawer: null,
    mini: false,
    selectedItem: 0,

    show: false,

    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    left: true,
    top: false,
    right: true,
    bottom: true,

    transition: "slide-x-reverse-transition",
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      const data = {
        id_number: AuthService.idNo,
        source: "WEB",
        type: "1",
      };
      v.$store.dispatch("Profile/getProfile", data);
    });
  },

  computed: {
    auth() {
      return AuthService;
    },
    navLinks() {
      return [
        {
          to: "/dashboard",
          icon: "mdi-view-dashboard",
          title: "Dashboard",
          disabled: false,
        },
        {
          to: "/profile",
          title: "Profile",
          icon: "mdi-account",
          disabled: false,
        },
        {
          to: "/preauth",
          title: "Preauth Status (LOU)",
          icon: "mdi-email-outline",
          disabled: false,
        },

        {
          to: "/contributions",
          title: "Contributions",
          icon: "mdi-database",
          disabled: false,
        },
        {
          to: "/facilities",
          title: "Facilities",
          icon: "mdi-office-building-marker",
          disabled: false,
        },
        {
          to: "/chat",
          title: "Chat",
          icon: "mdi-message-text",
          disabled: false,
        },
        {
          to: "/pay",
          title: "Pay Contributions",
          icon: "mdi-cash",
          disabled: false,
        },
        {
          to: "/arrears",
          title: "Pay Arrears",
          icon: "mdi-dots-horizontal",
          disabled: false,
        },
        {
          to: "/penalties",
          title: "Pay Penalties",
          icon: "mdi-gavel",
          disabled: false,
        },
      ];
    },

    profile() {
      return this.$store.getters["Profile/profileGetters"]("profile");
    },
  },
  methods: {
    logout() {
      AuthService.logout();
    },
    redirect() {
      this.$router.push({ name: "mainDashboard" });
    },
    showChildren() {
      this.selectedItem = undefined;
      this.show = !this.show;
    },
  },

  watch: {
    // selectedItem: {
    //   handler: function () {
    //     if (this.selectedItem === 6 || this.selectedItem === null) {
    //       this.selectedItem = undefined;
    //     }
    //   },
    // },
  },
};
</script>

<style scoped>
.nav-link {
  font-family: DM Sans, sans-serif;
}
.nav-title {
  font-size: 14px;
}
.newnav {
  font-size: 14px;
}

.newnav:hover {
  background-color: transparent; /* or transparent, or the default color */
}
</style>
