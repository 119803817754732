import AuthLayout from "./views/authLayout";

import Login from "@/modules/auth/components/login";
import NeedHelp from "@/modules/auth/components/NeedHelp";
import AddDetails from "@/modules/auth/components/AddDetails";
import Register from "./components/Register";
import LindaMamaHos from "@/modules/auth/components/LindaMamaHos";
import LindaMamaChoice from "@/modules/auth/components/lindaMamaChoice";
import LindaMamaSelfValidate from "@/modules/auth/components/LindaMamaSelfValidate";
import LindaMamaGuardian from "@/modules/auth/components/LindaMamaGuardian";
import Landing from "@/modules/auth/components/landing"
import termsAndConditions from "@/modules/auth/components/termsAndConditions"

export default [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "Landing",
        component: Landing,
      },
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "help",
        name: "NeedHelp",
        component: NeedHelp,
      },

      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "add-details",
        name: "AddDetails",
        component: AddDetails,
      },
      {
        path: "linda-Mama-Hospital",
        name: "LindaMamaHos",
        component:LindaMamaHos,
      },
      {
        path: "linda-Mama-Choice",
        name: "LindaMamaChoice",
        component:LindaMamaChoice,
      },
      {
        path: "linda-Mama-self",
        name: "LindaMamaSelfVal",
        component:LindaMamaSelfValidate,
      },

      {
        path: "linda-Mama-guardian",
        name: "LindaMamaGuardian",
        component:LindaMamaGuardian,
      },
      {
        path: "premium-terms-conditions",
        name: "termsAndConditions",
        component:termsAndConditions,
      }
    ],
  },
];
